<template>
    <article>
        <h1>Lanternfish intelligent search platform Privacy Policy</h1>
        <h2>1. Use of the information</h2>
        <p>1.1 This Website will not sell or lend the user’s personal or enterprise information to anyone unless it has obtained the user’s permission in advance;
        </p>
        <p>1.2 This website does not allow third parties to collect, edit, sell or gratis disseminate the user's personal or enterprise information by any means. If this Website finds any user involved in the above activities, this Website has the right to terminate the agreement with the user and close its account.
        </p>
        <h2>2. Information disclosure</h2>
        <p>The user’s personal or enterprise information will be disclosed in whole or in part under the following circumstances:</p>
        <ul>
            <li>
                With the consent of the user, this Website shall disclose the information of the user to the third parties;
            </li>
            <li>
                In the process of using this Website, for users which are involved in intellectual property disputes, after reviewing the materials submitted by the claimant, this Website believes that disclosure of said users’ information is helpful for dispute resolution, and will disclose as necessary, and within the law, for the purposes of resolving disputes;
            </li>
            <li>
                According to the relevant provisions of the law, or the requirements of the administrative and judicial authorities, this Website shall disclose the information of the user to a third party or administrative or judicial organs;
            </li>
            <li>
                If the user violates the the relevant laws and regulations of the People’s Republic of China and provisions or website rules, this Website shall disclose the information of the user to third parties;
            </li>
            <li>
                If in order to provide the products and services required by users, it is necessary to disclose certain personal or enterprise information of the user to third parties;
            </li>
            <li>
                Situations in which other information of the user that the law or this Website believes shall disclosed.
            </li>
        </ul>
        <h2>3. Information Security</h2>
        <p>3.1 When accepting the services of this Website for online transactions, the user shall protect his own personal or enterprise information;</p>
        <p>3.2 If the user finds their personal or enterprise information have been disclosed, especially the user‘s account or the account and the password of the “Payment Account Management”, the user shall contact the user service of this Website immediately, and this Website shall take appropriate measures in a reasonable timeframe. Before that, this Website shall not bear responsibility for the instructions that have been executed and/or the losses that users incur.</p>
    </article>
</template>

<script>
export default {

}
</script>

<style lang='less' scoped>
</style>
