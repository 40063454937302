<template>
    <article>
        <h1>蓝灯鱼隐私政策</h1>
        <h2>1. 信息使用</h2>
        <p>1.1 本网站不会向任何人出售或出借用户的个人或法人信息，除非事先得到用户得许可；
        </p>
        <p>本网站亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播用户的个人或法人信息。任何用户如从事上述活动，一经发现，本网站有权立即终止与该用户的用户协议，查封其账号。
        </p>
        <h2>2. 信息披露</h2>
        <p>用户的个人或法人信息将在下述情况下部分或全部被披露：</p>
        <ul>
            <li>
                经用户同意，向第三方披露；
            </li>
            <li>
                用户在使用本网站过程中涉及到知识产权类纠纷，有他方主张权利的，本网站在审核主张方提交的资料后认为披露用户信息有助于纠纷解决的；
            </li>
            <li>
                根据法律的有关规定，或者行政、司法机关的要求，向第三方或者行政、司法机关披露；
            </li>
            <li>
                若用户出现违反中国有关法律或者网站规定的情况，需要向第三方披露；
            </li>
            <li>
                为提供你所要求的产品和服务，而必须和第三方分享用户的个人或法人信息；
            </li>
            <li>
                其它本网站根据法律或者网站规定认为合适的披露。
            </li>
        </ul>
        <h2>3. 信息安全</h2>
        <p>3.1 在使用本网站服务进行网上交易时，请用户妥善保护自己的个人或法人信息，仅在必要的情形下向他人提供；</p>
        <p>3.2 如果用户发现自己的个人或法人信息泄密，尤其是用户账户或“支付账户管理”账户及密码发生泄露，请用户立即联络本网站客服，我们会在合理期限内采取相应措施。在此之前，本网站对已执行的指令及/或您已产生的损失不承担任何责任。</p>
    </article>
</template>

<script>
export default {

}
</script>

<style lang='less' scoped>
article {
	box-sizing: border-box;
	h1 {
		text-align: center;
		font-weight: 400;
		color: #022144;
	}
	p {
		color: #565d64;
		line-height: 1.6;
	}
	h2 {
		color: #022144;
		/*font-size: 1.2rem;*/
	}
	ul {
		list-style: decimal;
		color: #656e77;
	}
	padding-bottom: 20px;
}
</style>
