<template>
    <div>
        <zh-privacy v-if="$store.state.locale === 'cn'"></zh-privacy>
        <hk-privacy v-if="$store.state.locale === 'zh-hk'"></hk-privacy>
        <en-privacy v-if="$store.state.locale === 'en'"></en-privacy>
    </div>
</template>

<script>
import zhPrivacy from '@/components/legal/zh-cn/privacy'
import hkPrivacy from '@/components/legal/zh-hk/privacy'
import enPrivacy from '@/components/legal/en/privacy'
export default {
    components: {
        zhPrivacy,
        hkPrivacy,
        enPrivacy
    }
}
</script>

<style scoped>

</style>
