<template>
    <article>
        <h1>藍燈魚智能檢索平臺隱私政策</h1>
        <h2>1. 信息使用</h2>
        <p>1.1 本網站不會向任何人出售或出借用戶的個人或法人信息，除非事先得到用戶得許可；
        </p>
        <p>本網站亦不允許任何第三方以任何手段收集、編輯、出售或者無償傳播用戶的個人或法人信息。任何用戶如從事上述活動，壹經發現，本網站有權立即終止與該用戶的用戶協議，查封其賬號。
        </p>
        <h2>2. 信息披露</h2>
        <p>用戶的個人或法人信息將在下述情況下部分或全部被披露：</p>
        <ul>
            <li>
                經用戶同意，向第三方披露；
            </li>
            <li>
                用戶在使用本網站過程中涉及到知識產權類糾紛，有他方主張權利的，本網站在審核主張方提交的資料後認為披露用戶信息有助於糾紛解決的；
            </li>
            <li>
                根據法律的有關規定，或者行政、司法機關的要求，向第三方或者行政、司法機關披露；
            </li>
            <li>
                若用戶出現違反中國有關法律或者網站規定的情況，需要向第三方披露；
            </li>
            <li>
                為提供妳所要求的產品和服務，而必須和第三方分享用戶的個人或法人信息；
            </li>
            <li>
                其它本網站根據法律或者網站規定認為合適的披露。
            </li>
        </ul>
        <h2>3. 信息安全</h2>
        <p>3.1 在使用本網站服務進行網上交易時，請用戶妥善保護自己的個人或法人信息，僅在必要的情形下向他人提供；</p>
        <p>3.2 如果用戶發現自己的個人或法人信息泄密，尤其是用戶賬戶或“支付賬戶管理”賬戶及密碼發生泄露，請用戶立即聯絡本網站客服，我們會在合理期限內采取相應措施。在此之前，本網站對已執行的指令及/或您已產生的損失不承擔任何責任。</p>
    </article>
</template>

<script>
export default {

}
</script>

<style lang='less' scoped>
</style>
